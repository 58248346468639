import { getErrorLogMessage } from './get-error-log-message.ts'

type QuickFrameErrorOptions<ErrorCode extends string> = ErrorOptions & {
  code: ErrorCode
}

export abstract class QuickFrameError<ErrorCode extends string> extends Error {
  public code: QuickFrameErrorOptions<ErrorCode>['code']
  constructor(
    message: string,
    { code, ...options }: QuickFrameErrorOptions<ErrorCode>
  ) {
    super(message, options)
    this.name = this.constructor.name
    this.code = code
    Object.setPrototypeOf(this, new.target.prototype)
  }

  public get logMessage() {
    return getErrorLogMessage(this)
  }
}

// 400 - BAD_REQUEST
export class BadRequestError<
  ErrorCode extends string,
> extends QuickFrameError<ErrorCode> {}

// 401 - UNAUTHORIZED
type AuthenticationErrorCode =
  | 'missing_session'
  | 'missing_principal'
  | 'expired_session'

export class AuthenticationError extends QuickFrameError<AuthenticationErrorCode> {}

// 403 - FORBIDDEN
type AuthorizationErrorCode = 'forbidden'

export class AuthorizationError extends QuickFrameError<AuthorizationErrorCode> {
  constructor(
    message: string,
    options: Partial<QuickFrameErrorOptions<AuthorizationErrorCode>> = {}
  ) {
    super(message, { code: 'forbidden', ...options })
  }
}

// 404 - NOT_FOUND
export class NotFoundError<
  ErrorCode extends string,
> extends QuickFrameError<ErrorCode> {}

// 409 - CONFLICT
export class ConflictError<
  ErrorCode extends string,
> extends QuickFrameError<ErrorCode> {}

// 500 - INTERNAL_SERVER_ERROR
export class InternalServerError<
  ErrorCode extends string,
> extends QuickFrameError<ErrorCode> {}
