import type { FileArea } from '@mntn-dev/domain-types'
import { z } from 'zod'

type FilesConfiguration = Record<
  FileArea,
  {
    folder: string
  }
>

export const fileConfigurations = (<const>{
  'agencies.examples': {
    folder: 'organizations/agencies/examples',
  },
  'agencies.files.general': {
    folder: 'organizations/agencies/general',
  },
  'bids.examples': {
    folder: 'bids/examples',
  },
  'brands.files.general': {
    folder: 'organizations/brands/general',
  },
  'packages.examples': {
    folder: 'packages/examples',
  },
  'projects.thumbnails': {
    folder: 'projects/thumbnails',
  },
  'projects.assets.images': {
    folder: 'projects/assets/images',
  },
  'projects.assets.videos': {
    folder: 'projects/assets/videos',
  },
  'projects.assets.reference': {
    folder: 'projects/assets/reference',
  },
  // post-production review uploads
  'projects.services.assets.deliverables': {
    folder: 'projects/services/assets/deliverables',
  },
  // final assets
  'projects.services.assets.final': {
    folder: 'projects/services/assets/final',
  },
  // pre-production review uploads
  'projects.services.assets.reference': {
    folder: 'projects/services/assets/reference',
  },
  // user avatars
  'users.avatars': {
    folder: 'users/avatars',
  },
  'users.files.general': {
    folder: 'users/general',
  },
}) satisfies FilesConfiguration

/**
 * BEWARE: The code below creates types and schemas for the file configurations above.
 * Edit with caution.
 */

// FileAreas: Create an array of keys and ensure it's typed correctly
type ConfigurationKey = keyof typeof fileConfigurations

// FileFolders: Extract folders into an array and ensure it's typed correctly
type folderKeys = (typeof fileConfigurations)[ConfigurationKey]['folder']
const fileFolders = Object.values(fileConfigurations).map(
  (config) => config.folder
) as [folderKeys, ...folderKeys[]]
export const FileFolderSchema = z.enum([
  fileFolders[0],
  ...fileFolders.slice(1),
])
export type FileFolder = z.infer<typeof FileFolderSchema>
