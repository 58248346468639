import { FileIdSchema, ProjectIdSchema } from '@mntn-dev/domain-types'
import { z } from 'zod'
import { FileStatsSchema } from './file-stats.ts'
import { FileUploadResponseSchema } from './file-upload-response.ts'
import { QuickframeTagsSchema } from './metatags/quickframe-tags.ts'

export const MetaTagResultSchema = z.object({
  tags: QuickframeTagsSchema,
  fileStats: FileStatsSchema,
  tagsResult: z.string(),
  uploadResult: FileUploadResponseSchema,
})

export type MetaTagResult = z.infer<typeof MetaTagResultSchema>

export const MetaTagWebhookInputSchema = z.object({
  message: z.string(),
  status: z.number(),
  tags: QuickframeTagsSchema.pick({ assetFileUuid: true, projectUuid: true }),
})

export type MetaTagWebhookInput = z.infer<typeof MetaTagWebhookInputSchema>

export const MetaTagWebhookErrorSchema = z.object({
  message: z.string(),
  status: z.number(),
  fileId: FileIdSchema,
  projectId: ProjectIdSchema,
})

export type MetaTagWebhookError = z.infer<typeof MetaTagWebhookErrorSchema>
