import { env } from '@mntn-dev/env'

import type { ReactFilesContext } from './context.ts'
import type { TrpcReactClient } from './trpc-types.ts'
import {
  type UseUploadWidgetProps,
  createUseUploadWidget,
} from './use-upload-widget.ts'

import { getAvatarProxyUrl } from './get-avatar-proxy.url.ts'
import { getFileDownloadProxyUrl } from './get-file-download-proxy-url.ts'
import { getFileImageProxyUrl } from './get-file-image-proxy-url.ts'
import { saveFileToClient } from './save-file-to-client.ts'

export const ReactFileTools = (trpcReactClient: TrpcReactClient) => {
  const context: ReactFilesContext = {
    trpcReactClient,
    apiKey: env.NEXT_PUBLIC_CLOUDINARY_API_KEY,
    cloudName: 'magicsky',
  }

  return {
    useUploadWidget: createUseUploadWidget(context),
  }
}

export const ClientFileUtilities = (filesApiBaseUrl: string) => {
  return {
    getAvatarProxyUrl: getAvatarProxyUrl({ filesApiBaseUrl }),
    getFileDownloadProxyUrl: getFileDownloadProxyUrl({ filesApiBaseUrl }),
    getFileImageProxyUrl: getFileImageProxyUrl({ filesApiBaseUrl }),
    saveFileToClient,
  }
}

export type {
  AfterUploadEvent,
  AfterUploadHandler,
} from './after-upload-event.ts'

export type { UseUploadWidgetProps }
