import {
  type DateString,
  EnumBuilder,
  type HexColorString,
} from '@mntn-dev/utility-types'

import type { FileCategory } from '@mntn-dev/domain-types'
import type { z } from 'zod'
import type { StorageType } from './storage-type.ts'

export const [FileResourceTypes, FileResourceTypeSchema] = EnumBuilder(
  'auto',
  'image',
  'raw',
  'video'
)
export type FileResourceType = z.infer<typeof FileResourceTypeSchema>

export const convertFileCategoryToFileResourceType = (
  category: FileCategory
): FileResourceType => {
  return (
    {
      archive: 'raw',
      // Audio files are stored in cloudinary as a video resource
      audio: 'video',
      document: 'raw',
      image: 'image',
      image_document: 'image',
      raw: 'raw',
      video: 'video',
    } as const
  )[category]
}

export type ResourceDerivation = {
  transformation: string
  format: string
  bytes: number
  id: string
  url: string
  secure_url: string
}

export type ResourceFace = [number, number, number, number]

export type ResourceColor = [HexColorString, number]

export type ResourceContext<
  T extends Record<string, string> | undefined = undefined,
> = { custom: T }

export type ResourceUpdateRecord = {
  access_control_updated_at: DateString
  context_updated_at: DateString
  metadata_updated_at: DateString
  tags_updated_at: DateString
  updated_at: DateString
}

export type ResourceVersionRecord = {
  version_id: string
  version: string
  format: string
  size: number
  time: DateString
  restorable: boolean
}

export type Resource<
  StructuredMetadata extends Record<string, string> | undefined = undefined,
  ContextextualMetadata extends Record<string, string> | undefined = undefined,
> = {
  asset_id: string
  public_id: string
  version: number
  format: string
  resource_type: FileResourceType
  type: StorageType
  created_at: DateString
  bytes: number
  width: number
  height: number
  asset_folder: string
  display_name: string
  url: string
  secure_url: string
  tags: Array<string>
  context: ResourceContext<ContextextualMetadata>
  metadata: StructuredMetadata
  last_updated: ResourceUpdateRecord
  next_cursor: string
  derived: Array<ResourceDerivation>
  faces: [ResourceFace, ResourceFace]
  illustration_score: number
  semi_transparent: boolean
  grayscale: boolean
  colors?: Array<ResourceColor>
  versions: Array<ResourceVersionRecord>
}
