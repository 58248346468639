import { afterLast } from '@mntn-dev/utilities'
import { FileUploadError } from '../common/file-error.ts'
import type { SuccessEventInfo } from './upload-success-event.ts'

export type FileFormatErrorCode = 'invalid_extension'

export const FileFormatErrors = {
  invalidExtension: 'invalid_extension',
}

export const getFileExtension = (
  successEventInfo: Pick<
    SuccessEventInfo,
    'original_extension' | 'format' | 'public_id'
  >
): string => {
  if (successEventInfo.format) {
    return successEventInfo.format
  }

  const extensionFromPublicId = successEventInfo.public_id.includes('.')
    ? afterLast(successEventInfo.public_id, '.')
    : ''

  if (extensionFromPublicId) {
    return extensionFromPublicId
  }

  if (successEventInfo.original_extension) {
    return successEventInfo.original_extension
  }

  throw new FileUploadError(
    `Invalid file extension for public_id ${successEventInfo.public_id}`,
    {
      code: 'invalid_extension',
    }
  )
}
